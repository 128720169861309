<template>
  <div class="tab-content" id="PTtab-8" :key="'PTtab-8'" >
    <div>
      <div class="pagenamPT">
        <h3 class="pagename2 w100w">하부파트너 소속회원목록</h3>
      </div>
      <div class="PTsch">
        <select class="w100w">
          <option value="memId" selected>아이디</option>
          <option value="memNick">닉네임</option>
          <option value="recommenderId">상위유저</option>
        </select>
        <input class="w80w" type="text" :placeholder="$t('front.search.emptySearch')" v-model="searchMemId"/>
        <a @click="getPartnerMemberList(1)">
          <img src="@/assets/img/search.png" alt=""/>
        </a>
      </div>
      <div class="strTableWrap">
        <div class="subMemWrap">
          <!-- <p class="p10"></p> -->
          <h3>하부 파트너 목록<a @click="idlist=!idlist">i</a><span v-if="idlist" @click="idlist=!idlist">아이디 클릭시 목록 출력</span></h3>
          <ul class="subPT_1">
            <li>
              <a>
                <span class="box" :class="userData.partnerLevel">{{partnerLevelName(userData.partnerLevel)}}</span>
                {{userData.memId}}
              </a>
            </li>
            <li v-for="(item1) in partnerList" :key="item1.memId">
              <a :class="[item1.partnerLevel, { 'active': selectedLi === item1.memId }]" @click="getMyPartnerList(item1.memId, 'sub', item1)">
                <span class="box" :class="item1.partnerLevel">{{partnerLevelName(item1.partnerLevel)}}</span>
                {{item1.memId}}
              </a>

              <ul class="subPT_2" v-show="item1.open && item1.children">
                <li v-for="item2 in item1.children" :key="item2.memId">
                  <a :class="[item2.partnerLevel, { 'active': selectedLi === item2.memId }]" @click="getMyPartnerList(item2.memId, 'sub', item2)">
                    <span class="box" :class="item2.partnerLevel">{{partnerLevelName(item2.partnerLevel)}}</span>
                    {{item2.memId}}
                  </a>

                  <ul class="subPT_3" v-show="item2.open && item2.children">
                    <li v-for="item3 in item2.children" :key="item3.memId">
                      <a :class="[item3.partnerLevel, { 'active': selectedLi === item3.memId }]" @click="getMyPartnerList(item3.memId, 'sub', item3)">
                        <span class="box" :class="item3.partnerLevel">{{partnerLevelName(item3.partnerLevel)}}</span>
                        {{item3.memId}}
                      </a>

                      <ul class="subPT_4" v-show="item3.open && item3.children">
                        <li v-for="item4 in item3.children" :key="item4.memId">
                          <a :class="[item4.partnerLevel, { 'active': selectedLi === item4.memId }]" @click="getMyPartnerList(item4.memId, 'sub', item4)">
                            <span class="box" :class="item4.partnerLevel">{{partnerLevelName(item4.partnerLevel)}}</span>
                            {{item4.memId}}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="strTablescr">
          <div class="strTablePC">
            <h3>소속 회원 목록</h3>
            <table class="strTablePT">
              <colgroup>
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="12.5%">
                <col width="12.5%">
                <col width="15%">
              </colgroup>
              <thead>
              <tr>
                <th>아이디</th>
                <th>닉네임</th>
                <th>카지노요율</th>
                <th>슬롯요율</th>
                <th>보유금</th>
                <th>보유포인트</th>
                <th>가입 일시</th>
              </tr>
              </thead>
              <tbody>
                <template v-if="memberList.length > 0">
                  <tr v-for="(item) in memberList" :key="item.memId">
                    <td :class="item.myLevel">{{item.memId}}</td>
                    <td>{{item.memNick}}</td>
                    <td>{{item.casinoRate}}</td>
                    <td>{{item.slotRate}}</td>
                    <td>{{thousand(item.cashAmt)}}</td>
                    <td>{{thousand(item.pointAmt)}}</td>
                    <td>{{dateFormatAll(item.joinDate)}}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="7">소속 회원이 없습니다.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
              <div class="strTablePTM">
                <ul v-for="(item) in memberList" :key="item.memId">
                  <li :class="item.myLevel"><em>아이디</em>{{item.memId}}</li>
                  <li><em>닉네임</em>{{item.memNick}}</li>
                  <li><em>카지노요율</em>{{item.casinoRate}}</li>
                  <li><em>슬롯요율</em>{{item.slotRate}}</li>
                  <li><em>보유금</em>{{thousand(item.cashAmt)}}</li>
                  <li><em>보유포인트</em>{{thousand(item.pointAmt)}}</li>
                  <li><em>가입 일시</em>{{dateFormatAll(item.joinDate)}}</li>
                  <!-- <li><em>머니 관리</em>
                      <a class="bgr btn2 btn3" @click="onOpenMoneyPopup(item)">머니 지급</a>
                      <a class="bgg btn3" @click="setMoneyMerge(item)">통합머니전환</a>
                      <div v-if="item.openPopup" class="moneyg">
                        <a class="close" @click="onCloseMoneyPopup(item)"><img src="@/assets/img/icon_cancelB.svg" /></a>
                        <h2>보유머니: <span>{{thousand(item.cashAmt)}}</span></h2>
                        <input type="text" pattern="[0-9.,]+" :placeholder="$t('front.give.moneyInput')" v-model="item.inputCashAmt" @keyup="updateCashAmt(item, $event.target.value)" />
                        <div class="mbtn">
                            <a @click="setMoney(item, 10000)">1{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 30000)">3{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 50000)">5{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 100000)">10{{$t('front.cash.manwon')}}</a>
                        </div>
                        <div class="mbtn">
                            <a @click="setMoney(item, 30000)">3{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 50000)">5{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 100000)">10{{$t('front.cash.manwon')}}</a>
                            <a @click="setMoney(item, 0)" style="background: #730000;">{{$t('front.cash.correct')}}</a>
                        </div>
                        <div class="submit">
                            <a class="bgb btn2" @click="memCash('-3', item)">{{$t('front.give.give')}}</a>
                            <a class="bgr btn2" @click="memCash('3', item)">{{$t('front.give.back')}}</a>
                        </div>
                      </div>
                  </li> -->
                </ul>
              </div>
          </div>
        </div>
      </div>

      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="getPartnerMemberList"
      />
    </div>

    <transition name="fade">
      <TMmodal v-show="TMOpen" :isOpen="TMOpen" :type="'partner'" @close="onCloseTM" :userData="selectMem"/>
    </transition>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination.vue'
import {
  getRetailMyMemDirectList,
  partnerLevels, partnerMyMemIds, retailMyPartnerDirectList
} from '@/api/retail'
import { thousand } from '@/libs/utils'
import { memCashInOut } from '@/api/give'
import { mapState } from 'vuex'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'
import TMmodal from '@/components/common/TotalMoney.vue'

export default {
  name: 'subMemberList',
  components: { TMmodal, Pagination },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),

    formattedCashAmt () {
      if (!this.cashAmt) return ''
      return this.cashAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  data () {
    return {
      searchType: 'N',
      searchPartnerLevel: '',
      partnerLevelList: [],
      memberList: [],
      partnerList: [],
      partnerLevelObject: null,
      summaryNew: null,
      searchMemId: '',
      cashAmt: '',
      selectMem: {
        cashAmt: ''
      },
      list: [],
      isProcessing: false,
      moveTarget: null,
      TMOpen: false,
      subMenus: {
        1: true,
        2: false,
        3: false,
        4: false
      },
      selectedLi: null,
      idlist: false
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.searchMemId = this.userData.memId
    // this.getPartnerMemberList(1)
    this.getMyPartnerList(this.userData.memId)
    this.emitter.emit('Loading', false)
  },
  watch: {
    selectPartnerCode () {
      if (this.selectPartnerCode) {
        this.selectMem.cashAmt = ''
        this.getMyMemIds()
      }
    },
    selectMem () {
      if (this.selectMem) {
        this.selectMem.cashAmt = thousand(this.selectMem.cashAmt)
      }
    }
  },
  methods: {
    thousand,
    partnerLevelName (partnerLevel) {
      return PARTNER_LEVEL_NAME[partnerLevel]
    },
    toggleSubMenu (subMenuId) {
      this.subMenus[subMenuId] = !this.subMenus[subMenuId]
    },
    parseFormattedValue (value) {
      return value.replace(/,/g, '')
    },
    onCloseTM () {
      this.selectMem = null
      this.TMOpen = false
    },
    onChangePartnerLevel (partnerLevel) {
      this.searchPartnerLevel = partnerLevel
      this.getPartnerMemberList(1)
    },
    getMyMemIds () {
      const params = {
        code: this.selectPartnerCode,
        masterCode: 'partner'
      }
      partnerMyMemIds(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.myMemIdsList = result.data.memIds
        }
      })
    },
    getMyPartnerList (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      this.searchMemId = memId

      console.log('[REQ]retailMyPartnerDirectList : ', params)
      retailMyPartnerDirectList(params).then(res => {
        console.log('[RES]retailMyPartnerDirectList : ', res.data)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list
            item.open = true

            if (this.selectedLi === memId) {
              this.selectedLi = memId
            } else {
              this.selectedLi = memId
            }

            this.getPartnerMemberList(1)
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getPartnerMemberList (page) {
      this.emitter.emit('Loading', true)
      if (!page) {
        page = this.pageInfo.page
      }
      const params = {
        memId: this.searchMemId,
        page: page,
        partnerLevel: this.searchPartnerLevel,
        searchType: this.searchType,
        count_per_list: 40
      }
      console.log('[REQ]getRetailMyMemList : ', params)
      getRetailMyMemDirectList(params).then(res => {
        console.log('[RES]getRetailMyMemList : ', res.data)
        this.emitter.emit('Loading', false)
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.list)
          this.memberList = result.data.list

          this.memberList.forEach(item => {
            item.inputCashAmt = ''
          })

          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
        }
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log('partnerLevel : ', result.data)
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    async memCash (type, item) {
      const memId = item.memId
      const cashStr = item.inputCashAmt
      console.log(item)
      if (!cashStr) {
        await this.onAlert('warningart', 'front.give.moneyInput')
        return false
      }

      if (!memId) {
        await this.onAlert('warningart', '회원 아이디를 선택해주세요.')
        return false
      }

      if (!this.isProcessing) {
        const cash = Number(cashStr.replace(/,/g, ''))
        this.isProcessing = true

        const params = {
          memId: memId
        }
        if (type === '-3') {
          params.inAmt = cash
        } else {
          params.outAmt = cash
        }

        const message = this.$t('front.cash.moneyMoveConfirm', { memNick: memId, cashAmt: cashStr, type: type === '-3' ? '지급' : '회수' })

        const confirm = await this.onConfirm(message)
        if (confirm) {
          this.emitter.emit('Loading', true)
          memCashInOut(params, type).then(async response => {
            const result = response.data
            console.log(result)
            this.emitter.emit('Loading', false)
            if (result.resultCode === '0') {
              if (type === '-3') {
                await this.onCheck('지급 완료')
              } else {
                await this.onCheck('회수 완료')
              }
              item.inputCashAmt = ''
              this.getPartnerMemberList(this.pageInfo.page)
            } else {
              await this.onAlert('warningart', result.resultMessage)
            }
            this.isProcessing = false
          })
        } else {
          this.isProcessing = false
        }
      }
    },
    updateCashAmt (item, value) {
      const parts = item.inputCashAmt.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      // const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      if (v === '0' || v === '') {
        item.inputCashAmt = ''
      } else {
        item.inputCashAmt = n
      }
    },
    setMoney (item, value) {
      const currentMoney = Number(item.inputCashAmt.replace(/,/g, ''))
      console.log(currentMoney)
      if (value === 0) {
        item.inputCashAmt = (value).toString()
      } else {
        item.inputCashAmt = (currentMoney + value).toString()
      }
      this.updateCashAmt(item)
    }
  }
}
</script>
<style scoped src="@/styles/striNew.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped>
.strTablescr {width: 100%;}
@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
}
</style>
