import { createWebHistory, createRouter } from 'vue-router'
import { DOCUMENT_TITLE_PREFIX } from '@/libs/constants'

import { getLangCookie, tokenCheck, getSiteId } from '@/libs/auth-helper'

import store from '../store'

const routes = [
  {
    path: '/',
    name: 'blank',
    component: () => import('@/views/PageNotFound.vue'),
    meta: {
      layout: 'empty',
      guestOnly: true
    }
  },
  {
    name: 'partnerLogin',
    path: '/:prefix/login',
    component: () => import('@/views/member'),
    meta: {
      layout: 'default',
      text: '파트너 페이지',
      gnb: false,
      guestOnly: true
    }
  },
  {
    path: '/:prefix',
    name: 'main',
    component: () => import('@/views'),
    meta: {
      layout: 'empty',
      guestOnly: true
    },
    redirect: {
      name: 'partnerCashList'
    },
    children: [
      {
        name: 'partner',
        path: '/:prefix/main',
        component: () => import('@/views/member/partner/index.vue'),
        redirect: {
          name: 'partnerCashList'
        },
        meta: {
          layout: 'default',
          text: '파트너 페이지',
          gnb: false
        },
        children: [
          {
            name: 'partnerCashList',
            path: '/:prefix/cashList',
            component: () => import('@/views/member/partner/cashList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerBettingList',
            path: '/:prefix/bettingList',
            component: () => import('@/views/member/partner/bettingList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerList',
            path: '/:prefix/partnerList',
            component: () => import('@/views/member/partner/partnerList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'pointList',
            path: '/:prefix/pointList',
            component: () => import('@/views/member/partner/point/pointList.vue'),
            meta: {
              layout: 'default',
              text: '포인트적립내역',
              gnb: false
            }
          },
          {
            name: 'partnerCalculationList',
            path: '/:prefix/partnerCalculationList',
            component: () => import('@/views/member/partner/calculationList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerCalculationUserList',
            path: '/:prefix/partnerCalculationUserList',
            component: () => import('@/views/member/partner/calculationUserList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerMemberList',
            path: '/:prefix/partnerMemberList',
            component: () => import('@/views/member/partner/memberList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'onlineMemberList',
            path: '/:prefix/onlineMemberList',
            component: () => import('@/views/member/partner/onlineMemberList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'partnerMoneyMoveList',
            path: '/:prefix/partnerMoneyMoveList',
            component: () => import('@/views/member/partner/moneyMove.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'subMemberList',
            path: '/:prefix/subMemberList',
            component: () => import('@/views/member/partner/subMemberList.vue'),
            meta: {
              layout: 'default',
              text: '파트너 페이지',
              gnb: false
            }
          },
          {
            name: 'charge',
            path: '/:prefix/charge',
            component: () => import('@/views/member/partner/cash/charge.vue'),
            meta: {
              layout: 'default',
              text: '입금',
              gnb: false
            }
          },
          {
            name: 'exchange',
            path: '/:prefix/exchange',
            component: () => import('@/views/member/partner/cash/exchange.vue'),
            meta: {
              layout: 'default',
              text: '출금',
              gnb: false
            }
          },
          {
            name: 'point',
            path: '/:prefix/point',
            component: () => import('@/views/member/partner/point/pointListU.vue'),
            meta: {
              layout: 'default',
              text: '포인트',
              gnb: false
            }
          },
          {
            name: 'pointuse',
            path: '/:prefix/pointuse',
            component: () => import('@/views/member/partner/point/pointUse.vue'),
            meta: {
              layout: 'default',
              text: '포인트',
              gnb: false
            }
          },
          {
            name: 'pointuselist',
            path: '/:prefix/pointuselist',
            component: () => import('@/views/member/partner/point/pointUseList.vue'),
            meta: {
              layout: 'default',
              text: '포인트',
              gnb: false
            }
          },
          {
            name: 'subpartnerPointList',
            path: '/:prefix/subpartnerPointList',
            component: () => import('@/views/member/partner/subpartnerPointList.vue'),
            meta: {
              layout: 'default',
              text: '하부파트너 포인트적립내역',
              gnb: false
            }
          },
          {
            name: 'qna',
            path: '/:prefix/qna',
            component: () => import('@/views/member/partner/board/qna.vue'),
            meta: {
              layout: 'default',
              text: '문의',
              gnb: false
            }
          },
          {
            name: 'qnaread',
            path: '/:prefix/qnaread/:boardIdx',
            component: () => import('@/views/member/partner/board/qnaRead.vue'),
            meta: {
              layout: 'default',
              text: '문의',
              gnb: false
            }
          },
          {
            name: 'msg',
            path: '/:prefix/msg',
            component: () => import('@/views/member/partner/board/msg.vue'),
            meta: {
              layout: 'default',
              text: '메세지',
              gnb: false
            }
          },
          {
            name: 'msgread',
            path: '/:prefix/msgread/:msgIdx',
            component: () => import('@/views/member/partner/board/msgRead.vue'),
            meta: {
              layout: 'default',
              text: '메세지',
              gnb: false
            }
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'dashboard'
    }
  }
]

const router = createRouter({
  history: createWebHistory(), // 2.x대를 설치하시면 작동을 안합니다.
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ top: 0, left: 0 })
      }, 0)
    })
  }
})

router.beforeEach(async (to, from, next) => {
  const title = DOCUMENT_TITLE_PREFIX

  const prefix = to.params.prefix
  const siteIdInfo = getSiteId(prefix)
  if (!siteIdInfo || !siteIdInfo.siteId) {
    location.href = '/' + prefix
  }
  await store.dispatch('storeSiteIdInfo', siteIdInfo)

  const result = await tokenCheck()
  if (to.meta.guestOnly || result) {
    next()
  } else {
    const lang = getLangCookie()
    let msg = '로그인 이후 이용 가능합니다.'
    if (lang !== 'ko') {
      msg = 'Please Log-in'
    }
    alert(msg)
    location.href = '/' + prefix
  }

  document.title = title
})

export default router
