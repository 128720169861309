import httpClient from '@/libs/http-client'

export function getRetailSummary (params) {
  const url = '/api/retail/summary'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getRetailMyRate (params) {
  const url = '/api/member/rate'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getRetailMyInfo (params) {
  const url = '/api/retail/myinfo'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getRetailMyLoginMem (params) {
  const url = '/api/retail/myLoginMem'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getRetailMyMemList (params) {
  const url = '/api/retail/myMemList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getRetailMyMemDirectList (params) {
  const url = '/api/retail/myMemDirectList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getRetailMyMemRate (params) {
  const url = '/api/retail/rate'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getRetailOneloose (params) {
  const url = '/api/retail/detailList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getRetailMyMemberDetail (params) {
  const url = '/api/retail/myMemBetPointinfo'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getRetailMemberRate (params) {
  const url = '/api/retail/rate'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getOnelooseList (params) {
  const url = '/api/retail/detailList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function retailMyCalculate (params) {
  const url = '/api/retail/myCalculate'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function retailMemRate (params) {
  const url = '/api/retail/botMemRate'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function retailUpdate (params) {
  const url = '/api/retail/updateRate'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerLevels (params) {
  const url = '/api/retail/partnerLevels'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerMyMemIds (params) {
  const url = '/api/retail/myMemIds'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerRates (params) {
  const url = '/api/retail/partnerRates'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function partnerJoin (params) {
  const url = '/api/retail/partnerJoin'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getBottomBetList (params) {
  const url = '/api/retail/bottomBetList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getBottomCashList (params) {
  const url = '/api/retail/bottomCashList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getBottomBettingList (params) {
  const url = '/api/retail/bottomBetList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function retailMyMemberListByUser (params) {
  const url = '/api/retail/reportByUser'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function retailMyPartnerList (params) {
  const url = '/api/retail/myPartnerList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function retailMyPartnerDirectList (params) {
  const url = '/api/retail/myPartnerDirectList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function retailMyPartnerCashList (params) {
  const url = '/api/retail/bottomCashList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function subPartnerPointList (params) {
  const url = '/api/retail/pointList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
