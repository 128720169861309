<template>
  <div class="">
    <ul class="tablist">
      <li class="tabmenu" @click="goPageByName('point')">포인트 적립내역</li>
      <li class="tabmenu" @click="goPageByName('pointuse')">포인트 전환신청</li>
      <li class="tabmenu current" @click="goPageByName('pointUselist')">포인트 전환내역</li>
    </ul>
    <!--ul class="strbtnPT lastnone mb20">
      <li>{{$t('front.point.leftPoint')}}<span>{{thousand(sum ? sum.pointAmt: 0)}} P</span></li>
      <li>{{$t('front.point.monthPoint')}}<span>{{thousand(sum ? sum.thisMonthPoint : 0)}} P</span></li>
      <li>{{$t('front.point.prevMonthPoint')}}<span>{{thousand(sum ? sum.preMonthPoint: 0)}} P</span></li>
      <li>{{$t('front.point.allPoint')}}<span>{{thousand(sum ? sum.totalInPoint : 0)}} P</span></li>
      <li>{{$t('front.point.allUsePoint')}}<span>{{thousand(sum ? sum.totalOutPoint : 0)}} P</span></li>
      <li></li>
    </ul-->
    <div class="PTsch">
     <div class="datesearchPT">
        <date-filter @search="loadList(1)"
                     @update="onChangeDate"
                     :startDate="searchDate.startDate"
                     :endDate="searchDate.endDate"/>
     </div>
     <div class="datesearchM">
        <date-filter-mobile @search="loadList"
                     @update="onChangeDate" />
     </div>
    </div>

    <div class="strTablescr">
     <div class="strTablePC">
        <table class="strTablePT">
          <colgroup>
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
          </colgroup>
          <thead>
            <tr>
              <th>{{$t('front.board.applyDay')}}</th>
              <th>{{$t('front.board.divUse')}}</th>
              <th>{{$t('front.board.applyPoint')}}</th>
              <th>{{$t('front.board.processDay')}}</th>
              <th>{{$t('front.board.processState')}}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="list.length > 0">
              <template v-for="item in list" :key="item.updDt">
                <tr>
                  <td>{{dateFormatAll(item.regDt)}}</td>
                  <td>{{$t('front.board.moneychange')}}</td>
                  <td>{{thousand(item.point_inAmt.replace('-', ''))}}</td>
                  <td>{{dateFormatAll(item.updDt)}}</td>
                  <td>{{$t('front.board.complet')}}</td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
              </tr>
            </template>
          </tbody>
        </table>
     </div>
     <div class="strTableM">
        <div class="strTablePTM">
           <template v-if="list.length > 0">
             <template v-for="item in list" :key="item.updDt">
               <ul>
                 <li><em>{{$t('front.board.applyDay')}}</em>{{dateFormatAll(item.regDt)}}</li>
                 <li><em>{{$t('front.board.divUse')}}</em>{{$t('front.board.moneychange')}}</li>
                 <li><em>{{$t('front.board.applyPoint')}}</em>{{thousand(item.point_inAmt.replace('-', ''))}}</li>
                 <li><em>{{$t('front.board.processDay')}}</em>{{dateFormatAll(item.updDt)}}</li>
                 <li><em>{{$t('front.board.processState')}}</em>{{$t('front.board.complet')}}</li>
               </ul>
             </template>
           </template>
           <template v-else>
             <ul>
                <li>{{$t('front.common.notFoundList')}}</li>
             </ul>
           </template>
        </div>
     </div>
  </div>

    <pagination
        :pageNum="pageInfo.page"
        :pageSize="pageInfo.count_per_list"
        :totalCount="pageInfo.tatal_list_count"
        @goToPage="loadList" />

  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Pagination from '@/components/ui/Pagination'
import { getPointList } from '@/api/point'

export default {
  name: 'pointUselist',
  components: { DateFilter, DateFilterMobile, Pagination },
  data () {
    return {
      list: [],
      sum: {},
      pageInfo: {
        page: 1,
        count_per_list: 10,
        tatal_list_count: 0
      }
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    loadList (page) {
      const params = {
        pointType: 'out',
        page: page || this.pageInfo.page,
        startDate: this.searchDate.startDate,
        endDate: this.searchDate.endDate
      }

      console.log(params)

      getPointList(params).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
          const data = result.data
          this.list = data.list
          this.sum = data.sum
        }
      })
    }
  }
}
</script>
<style scoped>
.boardw li:nth-child(2){word-break: break-all;}
</style>
<style src="@/styles/striNew.css"></style>
