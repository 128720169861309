<template>
  <div class="tab-content" id="PTtab-12">
    <div class="pagenamPT">
        <h3 class="pagename2">하부파트너 포인트적립내역</h3>
      </div>
    <div class="tab-content">
      <div class="datesearchPT">
        <p>{{$t('front.give.date')}}:
          <input type="date" :value="searchForm.startDate" @change="onChangeStartDate"/>
          <span>~</span>
          <input type="date" :value="searchForm.endDate" @change="onChangeEndDate"/>
        </p>
        <ul>
          <li>
            받은 아이디: <input type="text" v-model="searchForm.botMemId"/>
          </li>
          <li>
            성공처리여부:
            <select v-model="searchForm.cashStatus">
              <option :value="''">{{$t('front.gameCategory.all')}}</option>
              <option :value="'1'">{{$t('front.give.ok')}}</option>
              <option :value="'-1'">{{$t('front.give.fail')}}</option>
            </select>
          </li>
          <li>
            종류:
            <select v-model="searchForm.pointType">
              <option :value="''">{{$t('front.gameCategory.all')}}</option>
              <option :value="'2'">관리자지급</option>
              <option :value="'-2'">관리자회수</option>
              <option :value="'3'">롤링</option>
              <option :value="'-4'">포인트전환</option>
              <option :value="'5'">가입첫충이벤트</option>
              <option :value="'6'">매일첫충이벤트</option>
              <option :value="'7'">매충전이벤트</option>
            </select>
          </li>
          <li>
            <a @click="loadData(1)">
              <img src="@/assets/img/search.png" alt=""/>
            </a>
          </li>
        </ul>
      </div>
      <div class="datesearchM moveM">
        <ul>
          <li>
            <em>{{$t('front.give.date')}}</em>:
            <input type="date" :value="searchForm.startDate" @change="onChangeStartDate"/>
            <span>~</span>
            <input type="date" :value="searchForm.endDate" @change="onChangeEndDate"/>
          </li>
          <li>
            <em>받은 아이디</em>: <input type="text" v-model="searchForm.botMemId"/>
          </li>
          <li>
            <em>성공처리여부</em>:
            <select v-model="searchForm.cashStatus">
              <option :value="''">{{$t('front.gameCategory.all')}}</option>
              <option :value="'1'">{{$t('front.give.ok')}}</option>
              <option :value="'-1'">{{$t('front.give.fail')}}</option>
            </select>
          </li>
          <li>
            <em>종류</em>:
            <select v-model="searchForm.pointType">
              <option :value="''">{{$t('front.gameCategory.all')}}</option>
              <option :value="'2'">관리자지급</option>
              <option :value="'-2'">관리자회수</option>
              <option :value="'3'">롤링</option>
              <option :value="'-4'">포인트전환</option>
              <option :value="'5'">가입첫충이벤트</option>
              <option :value="'6'">매일첫충이벤트</option>
              <option :value="'7'">매충전이벤트</option>
            </select>
          </li>
          <li class="PTsch">
            <a @click="loadData(1)">
              <img src="@/assets/img/search.png" alt=""/>
            </a>
          </li>
        </ul>
      </div>
      <div class="strTableWrap">
        <div class="subMemWrap">
          <!-- <p class="p10"></p> -->
          <h3>하부 파트너 목록<a @click="idlist=!idlist">i</a><span v-if="idlist" @click="idlist=!idlist">아이디 클릭시 목록 출력</span></h3>
          <ul class="subPT_1">
            <li>
              <a>
                <span class="box" :class="userData.partnerLevel">{{partnerLevelName(userData.partnerLevel)}}</span>
                {{userData.memId}}
              </a>
            </li>
            <li v-for="(item1) in partnerList" :key="item1.memId">
              <a :class="[item1.partnerLevel, { 'active': selectedLi === item1.memId }]" @click="getMyPartnerList(item1.memId, 'sub', item1)">
                <span class="box" :class="item1.partnerLevel">{{partnerLevelName(item1.partnerLevel)}}</span>
                {{item1.memId}}
              </a>

              <ul class="subPT_2" v-show="item1.open && item1.children">
                <li v-for="item2 in item1.children" :key="item2.memId">
                  <a :class="[item2.partnerLevel, { 'active': selectedLi === item2.memId }]" @click="getMyPartnerList(item2.memId, 'sub', item2)">
                    <span class="box" :class="item2.partnerLevel">{{partnerLevelName(item2.partnerLevel)}}</span>
                    {{item2.memId}}
                  </a>

                  <ul class="subPT_3" v-show="item2.open && item2.children">
                    <li v-for="item3 in item2.children" :key="item3.memId">
                      <a :class="[item3.partnerLevel, { 'active': selectedLi === item3.memId }]" @click="getMyPartnerList(item3.memId, 'sub', item3)">
                        <span class="box" :class="item3.partnerLevel">{{partnerLevelName(item3.partnerLevel)}}</span>
                        {{item3.memId}}
                      </a>

                      <ul class="subPT_4" v-show="item3.open && item3.children">
                        <li v-for="item4 in item3.children" :key="item4.memId">
                          <a :class="[item4.partnerLevel, { 'active': selectedLi === item4.memId }]" @click="getMyPartnerList(item4.memId, 'sub', item4)">
                            <span class="box" :class="item4.partnerLevel">{{partnerLevelName(item4.partnerLevel)}}</span>
                            {{item4.memId}}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="strTablescr">
          <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="17%">
                <col width="17%">
                <col width="17%">
                <col width="17%">
              </colgroup>
              <thead>
              <tr>
                <th>IDX</th>
                <th>아이디</th>
                <th>닉네임</th>
                <th>처리내용</th>
                <th>포인트 처리 금액</th>
                <th>처리 전 보유 포인트</th>
                <th>포인트 처리 일시</th>
              </tr>
              </thead>
              <tbody>
                <template v-if="list.length > 0">
                  <template v-for="(item, index) in list" v-bind:key="item.pointIdx">
                    <tr>
                      <td>{{((pageInfo.page-1) * 10) +  (index + 1)}}</td>
                      <td>{{ item.memId }}</td>
                      <td>{{ item.memNick }}</td>
                      <td>
                        <span v-if="item.pointType === '2'">관리자지급 </span>
                        <span v-if="item.pointType === '-2'">관리자회수 </span>
                        <span v-if="item.pointType === '3'">롤링 </span>
                        <span v-if="item.pointType === '-4'">포인트전환 </span>
                        <span v-if="item.pointType === '5'">가입첫충이벤트 </span>
                        <span v-if="item.pointType === '6'">매일첫충이벤트 </span>
                        <span v-if="item.pointType === '7'">매충전이벤트</span>
                      </td>
                      <td>{{ thousand(item.pointAmt) }}</td>
                      <td>{{ thousand(item.prePointAmt) }}</td>
                      <td>{{ item.pointRegDate.replace('T', ' ') }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="strTableM">
              <div class="strTablePTM">
                <template v-if="list.length > 0">
                    <template v-for="(item, index) in list" v-bind:key="item.pointIdx">
                      <ul>
                        <li><em>IDX</em>{{((pageInfo.page-1) * 10) +  (index + 1)}}</li>
                        <li><em>아이디</em>{{ item.memId }}</li>
                        <li><em>닉네임</em>{{ item.memNick }}</li>
                        <li>
                          <em>처리내용</em>
                          <template v-if="item.pointType === '2'">관리자지급 </template>
                          <template v-if="item.pointType === '-2'">관리자회수 </template>
                          <template v-if="item.pointType === '3'">롤링 </template>
                          <template v-if="item.pointType === '-4'">포인트전환 </template>
                          <template v-if="item.pointType === '5'">가입첫충이벤트 </template>
                          <template v-if="item.pointType === '6'">매일첫충이벤트 </template>
                          <template v-if="item.pointType === '7'">매충전이벤트</template>
                        </li>
                        <li><em>포인트 처리 금액</em>{{ thousand(item.pointAmt) }}</li>
                        <li><em>처리 전 보유 포인트</em>{{ thousand(item.prePointAmt) }}</li>
                        <li><em>포인트 처리 일시</em>{{ item.pointRegDate.replace('T', ' ') }}</li>
                      </ul>
                    </template>
                  </template>
                  <template v-else>
                  <ul>
                      <li>내역 없음</li>
                  </ul>
                </template>
              </div>
          </div>
        </div>
      </div>
      <pagination
        :pageNum="pageInfo.page"
        :pageSize="pageInfo.count_per_list"
        :totalCount="pageInfo.tatal_list_count"
        :className="'partnerPaging'"
        @goToPage="loadData"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination.vue'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import {
  getRetailMyLoginMem,
  partnerLevels,
  partnerMyMemIds,
  retailMyPartnerDirectList,
  subPartnerPointList
} from '@/api/retail'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'
import { mapState } from 'vuex'

export default {
  name: 'subpartnerPointList',
  components: { Pagination },
  data () {
    return {
      model: {},
      selectMemId: '',
      selectMem: {
        cashAmt: ''
      },
      cashAmt: '',
      list: [],
      searchForm: {
        topMemId: '',
        pointType: '',
        cashStatus: '',
        botMemId: '',
        startDate: getDateStr(getSubDaysDate(new Date(), 7)),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd')
      },
      partnerLevelList: [],
      partnerList: [],
      selectPartnerCode: '',
      myMemIdsList: [],
      isProcessing: false,
      loginMemList: [],
      selectedLi: null,
      idlist: false
    }
  },
  watch: {
    selectPartnerCode () {
      if (this.selectPartnerCode) {
        this.selectMem.cashAmt = ''
        this.getMyMemIds()
      }
    },
    selectMem () {
      if (this.selectMem) {
        this.selectMem.cashAmt = thousand(this.selectMem.cashAmt)
      }
    }
  },
  computed: {
    ...mapState([
      'userData'
    ]),
    formattedCashAmt () {
      if (!this.cashAmt) return ''
      return this.cashAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  async created () {
    this.getPartnerLevels()
    await this.getLoginMember()
    this.getMyPartnerList(this.userData.memId)
    this.loadData()
  },
  methods: {
    thousand,
    partnerLevelName (partnerLevel) {
      return PARTNER_LEVEL_NAME[partnerLevel]
    },
    async getLoginMember () {
      const res = await getRetailMyLoginMem()
      console.log(res)
      const result = res.data
      if (result.resultCode === '0') {
        this.loginMemList = result.data.loginMemList
      }
    },
    parseFormattedValue (value) {
      return value.replace(/,/g, '')
    },
    getMyMemIds () {
      const params = {
        code: this.selectPartnerCode,
        masterCode: 'partner'
      }
      partnerMyMemIds(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.myMemIdsList = result.data.memIds
        }
      })
    },
    getMyPartnerList (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      this.searchMemId = memId

      console.log('[REQ]retailMyPartnerDirectList : ', params)
      retailMyPartnerDirectList(params).then(res => {
        console.log('[RES]retailMyPartnerDirectList : ', res.data)
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list
            item.open = true

            if (this.selectedLi === memId) {
              this.selectedLi = memId
            } else {
              this.selectedLi = memId
            }

            this.loadData(1)
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
        }
      })
    },
    onChangeStartDate (event) {
      const startDate = event.target.value
      this.searchForm.startDate = startDate
    },
    onChangeEndDate (event) {
      const endDate = event.target.value
      this.searchForm.endDate = endDate
    },
    loadData (page) {
      if (!page) {
        page = this.pageInfo.page
      }
      if (this.selectedLi) {
        this.searchForm.topMemId = this.selectedLi
      }
      const form = JSON.parse(JSON.stringify(this.searchForm))
      form.startDate += ' 00:00:00'
      form.endDate += ' 23:59:59'
      form.pointTypeList = []
      // form.page = page

      if (form.pointType) {
        form.pointTypeList.push(form.pointType)
      }

      const params = {
        ...form,
        page: page,
        count_per_list: 40
      }
      this.emitter.emit('Loading', true)
      console.log('subPartnerPointList : ', params)
      subPartnerPointList(params).then(response => {
        this.emitter.emit('Loading', false)
        console.log('subPartnerPointList : ', response)
        const result = response.data
        if (result.resultCode === '0') {
          this.list = result.data.list
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
        }
      })
    }
  }
}
</script>
<style scoped src="@/styles/striNew.css"></style>
<style scoped>
.datesearchPT input[type="date"]::-webkit-calendar-picker-indicator{/*background: url(../../../assets/img/PTcalender.svg);display: block;background-repeat: no-repeat;background-size: contain;*/}
.strTablescr {width: 100%;}

@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
}
</style>
