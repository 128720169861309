<template>
  <div class="">
    <div class="pagenamPT">
      <h3 class="pagename2">출금</h3>
    </div>
    <div class="moneyread">
      <h3>{{$t('front.cash.moneyCheckList')}}</h3>
      <ul>
        <li>{{$t('front.cash.moneyCheckListN')}}</li>
        <li>{{$t('front.cash.moneyCheckListB')}}</li>
        <li>{{$t('front.cash.moneyCheckListD')}}</li>
      </ul>
    </div>

    <div class="moneyinfoappli">
      <div class="moneyinfo">
        <h3 class="applih">{{$t('front.cash.moneyOutInput')}}</h3>
        <p class="applip b0 mb0 pb0">{{$t('front.cash.moneyCheckListO')}}</p>
      </div>

      <div class="appliinputwrap">
        <ul class="appliinput">
          <li>{{$t('front.cash.haveCash')}}</li>
          <li><span class="gn w260">{{thousand(userData.cashAmt)}}</span>{{$t('front.common.money')}}</li>
        </ul>
        <ul class="appliinput">
          <li>{{$t('front.cash.moneyOutInput')}}</li>
          <li><input type="text" class="numb w260" pattern="[0-9.,]+" :placeholder="$t('front.cash.inputNumber')" v-model="getCurrentChargeMoney" />{{$t('front.common.money')}}</li>
        </ul>
        <ul class="moneybtnwrap ml120">
          <li class="one"><a @click="setMoney(10000)">1{{$t('front.cash.manwon')}}</a></li>
          <li class="one"><a @click="setMoney(30000)">3{{$t('front.cash.manwon')}}</a></li>
          <li class="one"><a @click="setMoney(50000)">5{{$t('front.cash.manwon')}}</a></li>
          <li class="two"><a @click="setMoney(100000)">10{{$t('front.cash.manwon')}}</a></li>
          <li class="two"><a @click="setMoney(300000)">30{{$t('front.cash.manwon')}}</a></li>
          <li class="two"><a @click="setMoney(500000)">50{{$t('front.cash.manwon')}}</a></li>
          <li class="thr"><a @click="setMoney(1000000)">100{{$t('front.cash.manwon')}}</a></li>
          <li class="four"><a @click="setMoney(0)">{{$t('front.cash.correct')}}</a></li>
        </ul>
        <ul class="appliinput">
          <li>{{$t('front.cash.withdrawPassword')}}</li>
          <li class="bbl"><input type="password" class="numb" :placeholder="$t('front.cash.inputPassword')" v-model="cashOutPass"></li>
        </ul>
        <div class="applibtns">
          <a @click="onSubmit">{{$t('front.cash.moneyCheckListL')}}</a>
        </div>
      </div>
    </div>

    <div class="strTablescr">
     <div class="strTablePC">
        <table class="strTablePT">
          <colgroup>
            <col width="20%">
            <col width="20%">
            <col width="20%">
            <col width="20%">
          </colgroup>
          <thead>
            <tr>
             <th>{{$t('front.board.applyDay')}}</th>
             <th>{{$t('front.board.withdrawMoney')}}</th>
             <th>{{$t('front.board.processDay')}}</th>
             <th>{{$t('front.board.processState')}}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="cashList.length > 0">
             <template v-for="item in cashList" :key="item.cashIdx">
                <tr>
                  <td>{{dateFormat(item.regDate)}}</td>
                  <td>{{thousand(item.cashAmt)}}</td>
                  <td>{{dateFormat(item.updDate)}}</td>
                  <td>{{computedCashStatus(item.cashStatus).text}}</td>
                </tr>
             </template>
            </template>
            <template v-else>
             <tr>
             </tr>
            </template>
          </tbody>
        </table>
     </div>
     <div class="strTableM">
        <div class="strTablePTM">
          <template v-if="cashList.length > 0">
             <template v-for="item in cashList" :key="item.cashIdx">
               <ul>
                 <li><em>{{$t('front.board.applyDay')}}</em>{{dateFormat(item.regDate)}}</li>
                 <li><em>{{$t('front.board.withdrawMoney')}}</em>{{thousand(item.cashAmt)}}</li>
                 <li><em>{{$t('front.board.processDay')}}</em>{{dateFormat(item.updDate)}}</li>
                 <li><em>{{$t('front.board.processState')}}</em>{{computedCashStatus(item.cashStatus).text}}</li>
               </ul>
             </template>
          </template>
          <template v-else>
             <ul>
                <li>{{$t('front.common.notFoundList')}}</li>
             </ul>
          </template>
        </div>
     </div>
 </div>

    <div class="boardpage mt20 mb20">
      <pagination :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  @goToPage="onCashListSearch" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'

import { cashDelete, cashOut, gameMoneyToCash } from '@/api/cash'
import store from '@/store'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'

export default {
  name: 'Exchange',
  components: {
    Pagination
  },
  watch: {
    type: function () {
      // if (this.type === 'list') {
      this.onCashListSearch()
      // }
    },
    getCurrentChargeMoney () {
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.getCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      console.log(n)
      this.getCurrentChargeMoney = n
    }
  },
  created () {
    this.onCashListSearch()
  },
  data () {
    return {
      type: 'apply',
      getCurrentChargeMoney: '',
      cashOutPass: '',
      allChecked: false,
      cashList: [],
      cashType: 'out',
      searchDate: {
        startDate: getDateStr(getSubDaysDate(new Date(), 2), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      }
    }
  },
  methods: {
    async moneyChange () {
      if (this.coinAmt && this.coinAmt !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmGameMoneyToCash')
        if (confirm) {
          const params = {
            cashAmt: this.coinAmt
          }
          gameMoneyToCash(params).then(response => {
            const result = response.data
            if (result.resultCode === '0') {
              store.dispatch('storeUserCoinAmt')
              store.dispatch('storeUserData')
              // this.userData.coinAmt = data.balance
              this.onCheck('front.cash.pointToCashComplete')
            }
          })
        }
      } else {
        this.onCheck('front.cash.emptyGamePoint')
      }
    },
    async onRemoveMsg () {
      let checkedCount = 0
      this.cashList.forEach(item => {
        if (item.checked) {
          checkedCount++
        }
      })
      if (checkedCount <= 0) {
        this.onCheck('front.cash.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.cash.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.cashList.length; i < iLen; i++) {
          const item = this.cashList[i]
          if (item.checked) {
            cashDelete(item).then(response => {
            })
          }
        }
        this.onCheck('front.cash.completeDeleteMessage')
        this.onCashListSearch()
      }
    },
    setMoney (value) {
      let money = Number(this.getCurrentChargeMoney.replace(/,/g, ''))
      if (value) {
        if (money + value <= this.userData.cashAmt) {
          money += value
        } else {
          this.onCheck('환전 금액이 현재 보유캐시보다 큽니다.')
        }
      } else {
        money = value
      }

      this.getCurrentChargeMoney = thousand(money)
    },
    async onSubmit (device = 'mobile') {
      if (!this.userData.outAmtYn || this.userData.outAmtYn === 'N') {
        this.onCheck('출금 정지된 상태입니다.')
        return false
      }
      if (!this.cashOutPass) {
        this.onCheck('출금 비밀번호를 입력해주세요.')
        return false
      }
      if (this.getCurrentChargeMoney && this.getCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmExchange')

        if (confirm) {
          const params = {
            memId: this.userData.memId,
            cashType: -1, // in,
            cashStatus: 'out',
            cashAmt: this.getCurrentChargeMoney.replace(/,/g, ''),
            cashOutPass: this.cashOutPass
          }

          cashOut(params).then(response => {
            store.dispatch('storeUserData').then(userData => {

            }).then(async responseInfo => {
              const result = response.data
              if (result.resultCode === '0') {
                const confirm = await this.onCheck('front.cash.completeExchange')
                if (confirm) {
                  if (device === 'mobile') {
                    this.replacePageByName('my')
                  } else {
                    location.reload()
                  }
                }
              } else if (result.resultCode === 'C099') {
                await this.onAlert('warningart', result.resultMessage)
              } else {
                await this.onCheck('api.' + result.resultCode)
              }
            })
          }).catch(err => {
            console.error(err)
            this.onCheck('출금 비밀번호를 확인해주세요.')
          })
        }
      } else {
        this.onCheck('front.cash.emptyPrice')
      }
    }
  }
}
</script>
<style scoped>

</style>

<style src="@/styles/striNew.css"></style>
