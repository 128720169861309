<template>
  <div class="alertLink">
     <!-- 각 알림 클릭했을 때 해당 페이지로 이동 및 박스 닫힘 -->
    <div class="alertToLinkBox" v-if="unReadMessageCount >= 1">
      <a @click="goPageByName('msg')">
        <span>쪽지가 도착했습니다</span>
      </a>
    </div>
    <div class="alertToLinkBox" v-if="unReadQnaCount">
      <a @click="goPageByName('qna')">
        <span>1:1 답변이 도착했습니다</span>
      </a>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Noti',
  title: 'Noti',
  data () {
    return {
    }
  },
  computed: {
    ...mapState([
      'unReadQnaCount',
      'unReadMessageCount'
    ])
  }
}
</script>

<style scoped>
  .alertLink {position: fixed;right: 30px;top: 60px; display: flex; flex-direction: column;align-items: center; justify-content: space-between; z-index: 9999; gap: 10px;}
  .alertToLinkBox {width: 250px; height: 50px; display: flex; justify-content: space-between; align-items: center; background: #5caca9;color: #fff; padding: 5px 10px; box-sizing: border-box;opacity: 0.8;cursor: pointer;}
  .alertToLinkBox:hover a {font-weight: 800;}
  .alertToLinkBox a {margin:0 auto; font-size: 20px; font-weight: 600;}
</style>
