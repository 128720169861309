<template>
  <div class="loginpage">
    <div class="loginBg">
      <video muted autoplay loop>
        <source src="../../assets/img/loginBg.mp4" type="video/mp4">
      </video>
      <div class="loginObj"></div>
    </div>
    <div id="loginWrap">
      <h1>PARTNER PAGE</h1>
      <h2>Welcome Back!</h2>
      <div class="inputWrap">
        <p>{{ $t('front.common.memId') }}</p>
        <ui-input class="loginasset" :type="'text'" @input="getMemId"/>
      </div>
      <div class="inputWrap">
        <p>{{ $t('front.common.password') }}</p>
        <ui-input class="loginasset" :type="'password'" @input="getMemPass" @keyup.enter="onSubmit"/>
        <i class="fa fa-eye"></i>
        <!--  :placeholder="$t('front.common.password')" -->
      </div>
      <div class="inputWrap">
        <p>자동입력방지코드</p>
        <section class="captcha-box">
          <VueClientRecaptcha
            :value="inputValue"
            :count="4"
            chars="12345"
            :hideLines="true"
            custom-text-color="#7cf2ef"
            @getCode="getCaptchaCode"
            @isValid="checkValidCaptcha"
          >
            <template #icon>
              <span style="color:white"><img src="../../assets/img/refresh.svg"></span>
            </template>
          </VueClientRecaptcha>
          <input
            v-model="inputValue"
            class="input"
            type="text"
            @keyup.enter="onSubmit"
          />
        </section>
      </div>
      <ui-button :className="'loginbtn loginasset'" :text="$t('front.login.loginButton')" @click="onSubmit"/>
    </div>
  </div>
</template>

<script>

import UiButton from '@/components/ui/UiButton'
import UiInput from '@/components/ui/UiInput'
import VueClientRecaptcha from 'vue-client-recaptcha'

import { setAuthData } from '@/libs/auth-helper'
import { mapState } from 'vuex'
import { signIn } from '@/api/member'
import store from '@/store'

export default {
  name: 'Main',
  components: {
    UiButton,
    UiInput,
    VueClientRecaptcha
  },
  async created () {

  },
  data () {
    return {
      model: {
        memId: null,
        memPass: null
      },
      data: {
        captchaCode: null,
        isValid: false
      },
      inputValue: null
    }
  },
  computed: {
    ...mapState([
      'siteIdInfo'
    ]),
    canSubmit () {
      console.log('아이디: ', this.model.memId)
      return this.model.memId && this.model.memPass
    }
  },
  methods: {
    getCaptchaCode (value) {
      this.data.captchaCode = value
    },
    checkValidCaptcha (value) {
      this.data.isValid = value
    },
    getMemId (value) {
      this.model.memId = value
    },
    getMemPass (value) {
      this.model.memPass = value
    },
    setReloadCaptcha () {
      setTimeout(() => {
        if (document.querySelector('.vue_client_recaptcha_icon')) {
          document.querySelector('.vue_client_recaptcha_icon').click()
        }
      }, 500)
    },
    async onSubmit () {
      let reloadCaptcha = false
      if (!this.data.isValid) {
        reloadCaptcha = true
        await this.onAlert('warningart', '자동 입력 방지 코드를 확인해주세요.')
      }

      if (this.model.memId === '') {
        reloadCaptcha = true
        this.onAlert('warningart', 'front.signin.emptyMemId')
      }
      if (this.model.memPass === '') {
        reloadCaptcha = true
        this.onAlert('warningart', 'front.signin.emptyMemPass')
      }

      if (!this.canSubmit) {
        reloadCaptcha = true
      }

      console.log(this.model, this.data, this.canSubmit)

      if (reloadCaptcha) {
        this.setReloadCaptcha()
        return false
      }

      console.log('11111')

      /*
      * api/member.js 참고
      */
      signIn(this.model).then(async response => {
        const result = response.data
        const data = result.data

        if (result.resultCode === '0') {
          const loginType = data.member.loginType

          if (loginType === 'PARTNER' || loginType === 'P_NORMAL' || !loginType) {
            setAuthData(data)
            store.commit('setUserData', data.member)
            store.dispatch('storeCommonCode').then(commonCode => {
              if (data.msgInfo) {
                store.dispatch('storeUserUnReadMessageCount', Number(data.msgInfo.newMsg))
              }
              this.$emit('close', 'signin')
            })

            this.replacePageByName('partner')
          } else {
            await this.onCheck('이용할수없는 페이지 입니다')
          }

          // setTimeout(function () {
          //   this.$emit('close', 'signin')
          // }.bind(this), 100)
        } else {
          // const confirm = await this.onConfirm('api.' + result.resultCode)
          // if (confirm) {
          //   this.$emit('signup')
          // }
          await this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.setReloadCaptcha()
        }
      }).catch(err => {
        if (err.message === 'no member') {
          // alert('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
        } else {
          alert(err.message)
        }
      })
    }
  }
}
</script>
<style scoped src="@/styles/GillSansNova.css"></style>
<style scoped lang="scss">
  .captcha-box {
    display: flex; gap: 10px;
    & .vue_client_recaptcha {
      background-color: #fff;
      width: 200px;
      display: inline-block;
      border-radius: 3px;
      border: 1px solid #00000029;
      box-sizing: border-box;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    }
  }

  :deep .captcha-box .vue_client_recaptcha .vue_client_recaptcha_icon {
    float: right;
    display: inline-block;
    position: relative;
    top: 50%;
    right: 10px;
    color: #fff;
    transform: translate(0, -50%);
  }

  .captcha-box .vue_client_recaptcha {
    background-color: #00413c;
  }

  .captcha-box .input {
    position: relative;
    width: 50%;
    height: 50px;
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.025em;
    border: 0;
    background: none;
    border-bottom: 2px solid #fff;
    color: #fff;
    vertical-align: top;
  }

</style>
<style scoped>
  .loginpage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  /* background: linear-gradient(to bottom, #ffffff, #e0ebf5); */
  }
  .loginpage::-webkit-scrollbar {display: none;}
  .loginBg{width: 100%; height: 100vh; position: absolute; z-index: -1; top: 0; left: 0;}
  .loginBg video { min-width: 100vw; min-height: 100vh; }
  .loginObj { position: absolute; width: 100%; height: 100%; background: url(../../assets/img/loginObj.svg) no-repeat; top: 0; left: 0; background-size: cover;}
  #loginWrap {display: flex; flex-direction: column; gap:20px; width: 350px;}
  #loginWrap h1 {color: #fff;   font-size: 47px; letter-spacing: -1px; font-family: 'GillSansNova'; text-align: center;}
  #loginWrap h2 {color: #fff; font-size: 25px; text-align: center; font-weight: 100; margin-bottom: 40px; }
  .inputWrap p {color: #fff; font-size: 16px; margin: 7px 0 15px;}
  .loginasset {width: 100%; height: 50px; box-sizing: border-box; background: none; border: 0; border-bottom: 2px solid #ffff; color: #fff; font-size: 16px; }
  .loginasset::placeholder {color: #fff;}
  input.loginasset {padding: 0 10px;}
  .loginbtn { background-image: linear-gradient(to bottom, #71c0be, #5caca9); color: #fff; border: 0; cursor: pointer; display: flex;justify-content: center; align-items: center; border-radius: 5px; font-size: 20px; font-weight: bold; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);}
</style>
