<template>
  <div class="tab-content" id="PTtab-5" :key="'PTtab-5'">
      <div class="pagenamPT">
        <h3 class="pagename2">입·출금내역</h3>
      </div>
      <div class="PTsch">
        <div class="datesearchPT">
          <date-filter :retail="true" @search="loadData(null, 1)"
                       @update="onChangeDateTable"
                       :defaultDay="0"
                       :startDate="mainTableDate.startDate"
                       :endDate="mainTableDate.endDate"
                       :isOldYn="true"
                       :id="'main-date-checkbox1'"
                       @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
        </div>
        <div class="datesearchM">
           <date-filter-mobile :retail="true" @search="loadData(null, 1)"
                              @update="onChangeDateTable"
                              :defaultDay="0"
                              :startDate="mainTableDate.startDate"
                              :endDate="mainTableDate.endDate"
                              :isOldYn="true"
                              :id="'main-date-checkbox1'"
                              @setOldYn="setOldYn" :oldYn="reqData.oldYn"
          />
        </div>
        <div class="searchPT">
          <select  v-model="reqData.cashType">
            <option value="" selected>전체</option>
            <option value="1">입금</option>
            <option value="-1">출금</option>
            <option value="2">관리자 지급</option>
            <option value="-2">관리자 회수</option>
            <option value="33">상위 파트너 지급</option>
            <option value="-33">상위 파트너 회수</option>
            <option value="4">포인트전환</option>
          </select>
          <select  v-model="reqData.searchType">
            <option value="memId" selected>아이디</option>
            <option value="memNick">닉네임</option>
            <option value="recommenderId">상위유저</option>
          </select>
          <input type="text" :placeholder="$t('front.search.emptySearch')"  v-model="reqData.searchWord"/>
          <a @click="loadData(null,1)">
            <img src="@/assets/img/search.png" alt=""/>
          </a>
        </div>
      </div>
      <div class="strTableWrap">
        <div class="subMemWrap">
          <h3>하부 파트너 목록<a @click="idlist=!idlist">i</a><span v-if="idlist" @click="idlist=!idlist">아이디 클릭시 목록 출력</span></h3>
          <p class="p10"></p>
          <ul class="subPT_1">
            <li>
              <a>
                <span class="box" :class="userData.partnerLevel">{{partnerLevelName(userData.partnerLevel)}}</span>
                {{userData.memId}}
              </a>
            </li>
            <li v-for="(item1) in partnerList" :key="item1.memId">
              <a :class="[item1.partnerLevel, { 'active': selectedLi === item1.memId }]" @click="getMyPartnerList(item1.memId, 'sub', item1)">
                <span class="box" :class="item1.partnerLevel">{{partnerLevelName(item1.partnerLevel)}}</span>
                {{item1.memId}}
              </a>

              <ul class="subPT_2" v-show="item1.open && item1.children">
                <li v-for="item2 in item1.children" :key="item2.memId">
                  <a :class="[item2.partnerLevel, { 'active': selectedLi === item2.memId }]" @click="getMyPartnerList(item2.memId, 'sub', item2)">
                    <span class="box" :class="item2.partnerLevel">{{partnerLevelName(item2.partnerLevel)}}</span>
                    {{item2.memId}}
                  </a>

                  <ul class="subPT_3" v-show="item2.open && item2.children">
                    <li v-for="item3 in item2.children" :key="item3.memId">
                      <a :class="[item3.partnerLevel, { 'active': selectedLi === item3.memId }]" @click="getMyPartnerList(item3.memId, 'sub', item3)">
                        <span class="box" :class="item3.partnerLevel">{{partnerLevelName(item3.partnerLevel)}}</span>
                        {{item3.memId}}
                      </a>

                      <ul class="subPT_4" v-show="item3.open && item3.children">
                        <li v-for="item4 in item3.children" :key="item4.memId">
                          <a :class="[item4.partnerLevel, { 'active': selectedLi === item4.memId }]" @click="getMyPartnerList(item4.memId, 'sub', item4)">
                            <span class="box" :class="item4.partnerLevel">{{partnerLevelName(item4.partnerLevel)}}</span>
                            {{item4.memId}}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="strTablescr">
         <div class="strTablePC">
            <table class="strTablePT">
              <colgroup>
                <col width="10%">
                <col width="10%">
                <col width="10%">
                <col width="15%">
                <col width="15%">
                <col width="15%">
                <col width="25%">
              </colgroup>
              <thead>
                <tr>
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>상위아이디</th>
                  <th>처리 전 보유금</th>
                  <th>처리금액</th>
                  <th>종류</th>
                  <th>처리시각</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="cashList.length > 0">
                  <template v-for="item in cashList" :key="item.updDate">
                    <tr>
                      <td>{{item.memId}}</td>
                      <td>{{item.memNick}}</td>
                      <td>{{item.recommenderId}}</td>
                      <td>{{thousand(item.preCashAmt)}}</td>
                      <td>{{thousand(item.cashAmt.replace('-', ''))}}</td>
                      <td>{{item.cashDesc}}</td>
                      <td>{{dateFormatAll(item.updDate)}}</td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                  </tr>
                </template>
              </tbody>
            </table>
         </div>
         <div class="strTableM">
            <div class="strTablePTM">
               <template v-if="cashList.length > 0">
                 <template v-for="item in cashList" :key="item.updDate">
                   <ul>
                     <li><em>아이디</em>{{item.memId}}</li>
                     <li><em>닉네임</em>{{item.memNick}}</li>
                     <li><em>상위 아이디</em>{{item.recommenderId}} / {{item.recommenderNick}}</li>
                     <li><em>처리 전 보유금</em>{{thousand(item.preCashAmt)}}</li>
                     <li><em>처리금액</em>{{thousand(item.cashAmt.replace('-', ''))}}</li>
                     <li><em>종류</em>{{item.cashDesc}}</li>
                     <li><em>처리시각</em>{{dateFormatAll(item.updDate)}}</li>
                   </ul>
                 </template>
               </template>
               <template v-else>
                 <ul>
                    <li>내역 없음</li>
                 </ul>
               </template>
            </div>
         </div>
      </div>
      </div>
      <pagination v-if="pageInfo"
                  :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  :className="'partnerPaging'"
                  @goToPage="goToPage"
      />
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import DateFilterMobile from '@/components/ui/DateFilterMobile'
import Pagination from '@/components/ui/Pagination.vue'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import { partnerLevels, retailMyPartnerCashList, retailMyPartnerDirectList } from '@/api/retail'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'
export default {
  name: 'PartnerCash',
  components: { DateFilter, DateFilterMobile, Pagination },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  data () {
    return {
      mainTableDate: {
        startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
      },
      reqData: {
        memId: '',
        cashType: '',
        searchType: '',
        searchWord: '',
        startDate: '',
        endDate: '',
        oldYn: 'N'
      },
      partnerList: [],
      cashList: [],
      selectedLi: null,
      idlist: false
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.getMyPartnerList(this.userData.memId)
    this.reqData.startDate = getDateStr(new Date(this.mainTableDate.startDate))
    this.reqData.endDate = getDateStr(new Date(this.mainTableDate.endDate))
    await this.loadData(this.userData.memId)
    this.emitter.emit('Loading', false)
  },
  methods: {
    thousand,
    goToPage (page) {
      this.loadData(this.selectedLi, page)
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    partnerLevelName (partnerLevel) {
      return PARTNER_LEVEL_NAME[partnerLevel]
    },
    getMyPartnerList (memId, type, item) {
      this.emitter.emit('Loading', true)
      const params = {
        memId: memId
      }
      this.searchMemId = memId

      retailMyPartnerDirectList(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.list
          if (type === 'sub') {
            item.children = list
            item.open = true

            if (this.selectedLi === memId) {
              this.selectedLi = memId
            } else {
              this.selectedLi = memId
            }

            this.loadData(memId, 1)
          } else {
            this.partnerList = list
          }
        }
        this.emitter.emit('Loading', false)
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangeDateTable (value) {
      this.reqData.startDate = getDateStr(new Date(value.startDate))
      this.reqData.endDate = getDateStr(new Date(value.endDate))
    },
    async loadData (memId, page) {
      if (!page) {
        page = this.pageInfo.page
      } else {
        this.pageInfo.page = page
      }
      this.emitter.emit('Loading', true)

      if (memId) {
        this.reqData.memId = memId
      }

      const params = { ...this.reqData, page: page, count_per_list: 40 }
      await retailMyPartnerCashList(params).then(res => {
        this.emitter.emit('Loading', false)
        const data = res.data.data
        if (data) {
          this.cashList = data.list

          if (data.pageInfo) {
            this.pageInfo = data.pageInfo
          } else {
            this.pageInfo.page = 1
            this.pageInfo.tatal_list_count = 0
          }
        }
      })
    }
  }
}
</script>

<style src="@/styles/striNew.css"></style>
<style scoped>
.strTablescr {width: 100%;}

@media (max-width: 1000px) {
  .strTablescr {width: 100%;}
  .strTablePTM ul {padding: 0;}
}
</style>
